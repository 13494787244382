.feedback-section .no-feedback {
  min-height: 100px;
}
.feedback-section .new-feedback-btn {
  gap: 5px;
}

.feedback-summary-section .rating-summary-numbers {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid var(--gray-100);
}
.feedback-summary-section .rating-summary-numbers .average-rating {
  font-size: 2rem;
}
.feedback-summary-section .rating-summary-numbers .feedback-count {
  margin-top: -0.5rem;
}
.feedback-summary-section .rating-progress-bar-section {
  padding-bottom: 2rem;
}
.feedback-summary-section .rating-progress-bar-section .rating-bar-title {
  margin-left: -15px;
}
.feedback-summary-section .rating-progress-bar-section .rating-progress-bar {
  margin-bottom: 4px;
  height: 7px;
  margin-top: 6px;
}
.feedback-summary-section .rating-progress-bar-section .progress-bar-cosmetic {
  background-color: var(--gray-600);
  border-radius: var(--border-radius);
}
.feedback-summary-section .ratings-pill {
  background-color: var(--gray-100);
  padding: 0.5rem 1rem;
  border-radius: 66px;
}

.feedback-history .feedback-content {
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
}
.feedback-history .feedback-content:last-child {
  border-bottom: 1px solid var(--border-color);
}